import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Menu } from "../menuCompont/Menu";
import axios from "axios";
import "./questionsPage.css";

interface QuestionInterface {
    _id: string;
    enunciado: string;
    alternativa01: string;
    alternativa02: string;
    alternativa03: string;
    alternativaCorreta: string;
    idQuestaoVinculada: string;
}

interface DataProvas {
    _id: string;
    idQuestoes: String,
    status: String,
    idVideoVinculado: String,
    nomeProva: String
}

export function QuestionsPage() {
    const { id } = useParams();
    const location = useLocation();
    const videoData = location.state;
    const [categoriaProva, setCategoriaProva] = useState("");

    const [formData, setFormData] = useState({
        enunciado: "",
        alternativa01: "",
        alternativa02: "",
        alternativa03: "",
        alternativaCorreta: "",
        idQuestaoVinculada: videoData?._id || "",
        categoria: videoData?.classVideo,
    });

    const [dataQuestions, setDataQuestions] = useState<QuestionInterface[]>([]);
    const [editingId, setEditingId] = useState<string | null>(null);
    const [editFormData, setEditFormData] = useState<Partial<QuestionInterface>>(
        {}
    );

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleEditChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setEditFormData({
            ...editFormData,
            [name]: value,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const token = sessionStorage.getItem("authToken");
            if (!token) {
                alert("Token não encontrado. Faça login novamente.");
                return;
            }

            const updatedFormData = {
                ...formData,
                categoria: videoData.classVideo,
            };

            const response = await axios.post(
                "https://api-protmar-educacional.vercel.app/postquestao",
                updatedFormData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            console.log(updatedFormData)

            if (response.status === 201) {
                alert("Questão criada com sucesso!");
                setFormData({
                    enunciado: "",
                    alternativa01: "",
                    alternativa02: "",
                    alternativa03: "",
                    alternativaCorreta: "",
                    idQuestaoVinculada: videoData._id,
                    categoria: videoData.classVideo
                });
                fetchQuestions();
            }
        } catch (error: any) {
            console.error("Erro ao criar questão:", error);
            alert("Erro ao criar questão.");
        }
    };

    const [dataProvas, setDataProvas] = useState<DataProvas[]>([]);
    const fetchQuestions = async () => {
        try {
            const token = sessionStorage.getItem("authToken");
            const response = await axios.get(
                `https://api-protmar-educacional.vercel.app/getallquestoes/${videoData._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
            setDataQuestions(response.data);
        } catch (error) {
            console.error("Erro ao buscar questões:", error);
        }
    };

    const fetchQuestions2 = async () => {
        try {
            const token = sessionStorage.getItem("authToken");

            
            const response = await axios.get(
                `https://api-protmar-educacional.vercel.app/getprovasbyvideo/${videoData._id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if(response.status === 200){
                setDataProvas(response.data);
                return;
            }
            
        } catch {
            setDataProvas([]);
        }
    }; 

    const handleEdit = (id: string) => {
        const questionToEdit = dataQuestions.find((q) => q._id === id);
        if (questionToEdit) {
            setEditingId(id);
            setEditFormData({ ...questionToEdit });
        }
    };

    const handleCancelEdit = () => {
        setEditingId(null);
        setEditFormData({});
    };

    const handleSaveEdit = async (id: string) => {
        try {
            const token = sessionStorage.getItem("authToken");
            if (!token) {
                alert("Token não encontrado. Faça login novamente.");
                return;
            }

            const response = await axios.put(
                `https://api-protmar-educacional.vercel.app/updatequestao/${id}`,
                editFormData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.status === 200) {
                alert("Questão atualizada com sucesso!");
                setEditingId(null);
                fetchQuestions();
            }
        } catch (error) {
            console.error("Erro ao atualizar questão:", error);
            alert("Erro ao atualizar questão.");
        }
    };


    const handleDelete = async (id: string) => {
        // Exibe uma caixa de confirmação antes de excluir a questão
        const confirmDelete = window.confirm("Tem certeza de que deseja excluir esta questão?");

        if (!confirmDelete) {
            return; // Se o usuário cancelar, a função é interrompida
        }

        try {
            const token = sessionStorage.getItem("authToken");
            if (!token) {
                alert("Token não encontrado. Faça login novamente.");
                return;
            }

            const response = await axios.delete(
                `https://api-protmar-educacional.vercel.app/deletequestao/${id}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                alert("Questão excluída com sucesso!");
                fetchQuestions(); // Atualiza a lista de questões após a exclusão
            }
        } catch (error) {
            console.error("Erro ao excluir questão:", error);
            alert("Erro ao excluir questão.");
        }
    };


    useEffect(() => {
        if (videoData && videoData._id) {
            fetchQuestions();
        }
    }, [videoData]);

    useEffect(() => {
        if (videoData && videoData._id) {
            fetchQuestions2();
        }
    }, [videoData]);
    

    const [showModal, setShowModal] = useState(false);


    // Funções para abrir e fechar o modal
    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);
    
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
    
        // Verifica se o checkbox foi selecionado ou desmarcado
        if (event.target.checked) {
          setSelectedOptions((prevOptions) => [...prevOptions, value]);
        } else {
          setSelectedOptions((prevOptions) =>
            prevOptions.filter((option) => option !== value)
          );
        }
      };

      async function criarProva(id:String) {
        try {
            // Obtém o token de autenticação
            const token = sessionStorage.getItem("authToken");
    
            // Verifica se o token existe
            if (!token) {
                alert("Token não encontrado. Faça login novamente.");
                return;
            }

            // Verifica se o vídeo já possui uma prova
            
            if(dataProvas.length > 0){
                alert("Video ja possui uma prova!");
                closeModal();
                return;
            }
          
    
            // Verifica se ao menos uma questão foi selecionada
            if (selectedOptions.length === 0) {
                alert("Selecione ao menos uma questão!");
                return;
            }
    
            // Faz a requisição POST usando axios
            const response = await axios.post(
                "https://api-protmar-educacional.vercel.app/postprova",
                {
                    idQuestoes: selectedOptions,
                    status: "não iniciada",
                    idVideoVinculado: videoData._id,
                    nomeProva
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );
    
            // Verifica a resposta da requisição
            if (response.status === 200) {
                alert("Prova criada com sucesso!");
                setSelectedOptions([]); // Limpa as questões selecionadas
                fetchQuestions2(); // Atualiza a lista de questões
                closeModal(); // Fecha o modal de criação de prova
            } else {
                alert("Erro ao criar a prova. Tente novamente.");
            }
        } catch (error) {
            // Captura erros de rede ou de resposta da API
            console.error("Erro ao criar a prova:", error);
            alert("Ocorreu um erro ao criar a prova. Tente novamente.");
        }
    }
    
    
        async function handleDeleteProva(id: string) {
            try {
                const token = sessionStorage.getItem("authToken");
        
                // Verifica se o token existe
                if (!token) {
                    alert("Token não encontrado. Faça login novamente.");
                    return;
                }
        
                // Desabilitar o botão ou impedir múltiplos cliques até a requisição ser concluída
                const deleteButton = document.getElementById(`delete-btn-${id}`);
                if (deleteButton) {
                    deleteButton.setAttribute('disabled', 'true');
                }
        
                // Faz a requisição DELETE usando axios
                const response = await axios.delete(`https://api-protmar-educacional.vercel.app/deleteprova/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
        
                // Sucesso na exclusão
                alert("Prova excluída com sucesso!");
                // Atualize a interface conforme necessário (ex: removendo a prova da lista)
                fetchQuestions2();
        
            } catch (error) {
                // Tratamento de erro
                console.error("Erro ao excluir a prova:", error);
                alert("Erro ao excluir a prova. Tente novamente mais tarde.");
            } finally {
                // Reabilitar o botão de exclusão ou permitir novos cliques após a operação
                const deleteButton = document.getElementById(`delete-btn-${id}`);
                if (deleteButton) {
                    deleteButton.removeAttribute('disabled');
                }
            }
        }


    const [nomeProva, setNomeProva] = useState<string>("");

    

    return (
        <main id="questionsPageMain" className="bg-light py-5">
            <Menu />
            <div className="container">
                <h1 className="text-center mb-4">Questões do vídeo: {videoData?.name || "N/A"}</h1>

                <div>
                    

                    {/* Modal */}
                    {showModal && (
                        <div
                            className="modal fade show"
                            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
                            id="exampleModal"
                            tabIndex={-1}
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                        >
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">
                                            Selecione as Questões
                                        </h5>
                                        <button
                                            type="button"
                                            className="btn-close"
                                            onClick={closeModal} // Fecha o modal ao clicar
                                            aria-label="Close"
                                        ></button>
                                    </div>
                                    <div className="modal-body">
                                        <label htmlFor="selectQuestao" className="form-label">Selecione as Questões</label>
                                        <input required onChange={(e:any) => {setNomeProva(e.target.value)}} type="text" name="" id="" placeholder="Nome da Prova" />
                                        {
                                            dataQuestions.map((question) => (
                                                <div className="form-check" key={question._id}>
                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        name="selectedQuestions"
                                                        id={`question-${question._id}`}
                                                        value={question._id}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <label className="form-check-label" htmlFor={`question-${question._id}`}>
                                                        {question.enunciado}
                                                    </label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                    <div className="modal-footer">

                                        <button onClick={() => {criarProva(videoData._id)}} type="button" className="btn btn-primary">
                                            Criar Prova
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className="card shadow-sm">
                    <div className="card-body">
                        {/* Botão para abrir o modal */}
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={openModal} // Abre o modal ao clicar
                    >
                        Criar Prova
                    </button>
                    <br />
                    <br />
                        <h3>Prova Criada</h3>
                        {dataProvas.map((question) => (
                            <table key={question._id} className="table mb-4 table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan={2}>Prova ID: {question._id}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Id Vídeo Vinculado</th>
                                        <td>
                                            {question.idVideoVinculado}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Nome da Prova</th>
                                        <td>
                                            {question.nomeProva}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Id das Questões</th>
                                        <td>
                                            {question.idQuestoes + ""}
                                        </td>
                                    </tr>

                                    <tr>
                                        <th>Ações</th>
                                        <td>
                                            {<>
                                                    <button
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => {handleDeleteProva(question._id)}}
                                                    >
                                                        Excluir
                                                    </button>
                                                </>}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        ))}
                    </div>
                </div>
                <br />

                <div className="card shadow-sm mb-4">
                    <div className="card-body">
                        <h3>Registro de Questões</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="enunciado" className="form-label">
                                    Enunciado
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="enunciado"
                                    name="enunciado"
                                    placeholder="Enunciado"
                                    value={formData.enunciado}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="row">
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="alternativa01" className="form-label">
                                        Alternativa 01 INCORRETA
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="alternativa01"
                                        name="alternativa01"
                                        placeholder="Alternativa 01 INCORRETA"
                                        value={formData.alternativa01}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="alternativa02" className="form-label">
                                        Alternativa 02 INCORRETA
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="alternativa02"
                                        name="alternativa02"
                                        placeholder="Alternativa 02 INCORRETA"
                                        value={formData.alternativa02}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="col-md-4 mb-3">
                                    <label htmlFor="alternativa03" className="form-label">
                                        Alternativa 03 INCORRETA
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="alternativa03"
                                        name="alternativa03"
                                        placeholder="Alternativa 03 INCORRETA"
                                        value={formData.alternativa03}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="mb-3">
                                <label htmlFor="alternativaCorreta" className="form-label">
                                    Alternativa 04 CORRETA
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="alternativaCorreta"
                                    name="alternativaCorreta"
                                    placeholder="Alternativa 04 CORRETA"
                                    value={formData.alternativaCorreta}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <button type="submit" className="btn btn-primary w-100">
                                Salvar Questão
                            </button>
                        </form>
                    </div>
                </div>

                <div className="card shadow-sm">
                    <div className="card-body">
                        <h3>Questões Vinculadas</h3>
                        {dataQuestions.map((question) => (
                            <table key={question._id} className="table mb-4 table-bordered">
                                <thead>
                                    <tr>
                                        <th colSpan={2}>Questão ID: {question._id}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th style={{width:"200px"}}>Enunciado</th>
                                        <td>
                                            {editingId === question._id ? (
                                                <input
                                                    type="text"
                                                    name="enunciado"
                                                    value={editFormData.enunciado || ""}
                                                    onChange={handleEditChange}
                                                    className="form-control"
                                                />
                                            ) : (
                                                question.enunciado
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{width:"200px"}}>Alternativa 01</th>
                                        <td>
                                            {editingId === question._id ? (
                                                <input
                                                    type="text"
                                                    name="alternativa01"
                                                    value={editFormData.alternativa01 || ""}
                                                    onChange={handleEditChange}
                                                    className="form-control"
                                                />
                                            ) : (
                                                question.alternativa01
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{width:"200px"}}>Alternativa 02</th>
                                        <td>
                                            {editingId === question._id ? (
                                                <input
                                                    type="text"
                                                    name="alternativa02"
                                                    value={editFormData.alternativa02 || ""}
                                                    onChange={handleEditChange}
                                                    className="form-control"
                                                />
                                            ) : (
                                                question.alternativa02
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{width:"200px"}}>Alternativa 03</th>
                                        <td>
                                            {editingId === question._id ? (
                                                <input
                                                    type="text"
                                                    name="alternativa03"
                                                    value={editFormData.alternativa03 || ""}
                                                    onChange={handleEditChange}
                                                    className="form-control"
                                                />
                                            ) : (
                                                question.alternativa03
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{width:"200px"}}>Alternativa Correta</th>
                                        <td>
                                            {editingId === question._id ? (
                                                <input
                                                    type="text"
                                                    name="alternativaCorreta"
                                                    value={editFormData.alternativaCorreta || ""}
                                                    onChange={handleEditChange}
                                                    className="form-control"
                                                />
                                            ) : (
                                                question.alternativaCorreta
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th style={{width:"200px"}}>Ações</th>
                                        <td>
                                            {editingId === question._id ? (
                                                <>
                                                    <button
                                                        className="btn btn-success btn-sm me-2"
                                                        onClick={() => handleSaveEdit(question._id)}
                                                    >
                                                        Salvar
                                                    </button>
                                                    <button
                                                        className="btn btn-secondary btn-sm"
                                                        onClick={handleCancelEdit}
                                                    >
                                                        Cancelar
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button
                                                        className="btn btn-warning btn-sm me-2"
                                                        onClick={() => handleEdit(question._id)}
                                                    >
                                                        Editar
                                                    </button>
                                                    <button
                                                        className="btn btn-danger btn-sm"
                                                        onClick={() => handleDelete(question._id)}
                                                    >
                                                        Excluir
                                                    </button>
                                                </>
                                            )}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        ))}
                    </div>
                </div>
            </div>
        </main>
    );
}
