import React, { useEffect, useState } from "react";
import { data, useLocation, useParams } from "react-router-dom";
import { Menu } from "../menuCompont/Menu";
import "./videoPage.css";
import axios from "axios";

interface QuestionInterface {
  _id: string;
  enunciado: string;
  alternativa01: string;
  alternativa02: string;
  alternativa03: string;
  alternativaCorreta: string;
  idQuestaoVinculada: string;
}

export function VideoPage() {
  const { id } = useParams();
  const location = useLocation();
  const videoData = location.state;
  const [showModal, setShowModal] = useState(false);
  const [allQuestions, setAllQuestions] = useState<QuestionInterface[]>([]);
  const [questoesSortidas, setQuestoesSortidas] = useState<QuestionInterface[]>([]);
  const [selectedAnswers, setSelectedAnswers] = useState<Record<string, { valorPostado: string; valorCorreto: string }>>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [carregandoVideo, setCarregandoVideo] = useState(true);
  const [carregandoQuestoes, setCarregandoQuestoes] = useState(false);

  const [shuffledAlternatives, setShuffledAlternatives] = useState<
    { _id: string; enunciado: string; alternativas: { texto: string; correta: boolean }[] }[]
  >([]);

  // Função para embaralhar array
  const shuffleArray = (array: any[]) => {
    return [...array].sort(() => Math.random() - 0.5);
  };

  // Função para abrir o modal
  const openModal = () => setShowModal(true);

  // Função para fechar o modal com confirmação
  const closeModalWithConfirmation = () => {
    if (window.confirm("Você tem certeza de que deseja fechar sem enviar suas respostas?")) {
      setShowModal(false);
    }
  };

  // Função para buscar prova e questões associadas
  const [dataProva, setDataPova] = useState<any>({})
  const [categoriaCurso, setCategoriaCurso] = useState<string>("")
  async function fetchProva() {
    try {
      setCarregandoQuestoes(true);
      const token = sessionStorage.getItem("authToken");
      if (!token) {
        console.error("Token de autenticação não encontrado.");
        return;
      }

      const response = await axios.get(`https://api-protmar-educacional.vercel.app/getprovasbyvideo/${videoData._id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setDataPova(response.data)

      const questionIds = response.data.map((e: any) => e.idQuestoes).flat();
      const questoesPromises = questionIds.map(async (id: string) => {
        const { data } = await axios.get(`https://api-protmar-educacional.vercel.app/getquestaoindividual/${id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setCategoriaCurso(data.categoria)
        return data;
      });

      const questoes = await Promise.all(questoesPromises);
      setAllQuestions(questoes);
      const sortedQuestions = questoes.sort(() => Math.random() - 0.5).slice(0, 10);
      setQuestoesSortidas(sortedQuestions);

      // Prepara as alternativas embaralhadas
      const shuffledData = sortedQuestions.map((questao) => {
        const alternativas = shuffleArray([
          { texto: questao.alternativa01, correta: false },
          { texto: questao.alternativa02, correta: false },
          { texto: questao.alternativa03, correta: false },
          { texto: questao.alternativaCorreta, correta: true },
        ]);
        return { _id: questao._id, enunciado: questao.enunciado, alternativas };
      });

      setShuffledAlternatives(shuffledData);
    } catch (error) {
      console.error("Erro ao carregar as questões:", error);
    } finally {
      setCarregandoQuestoes(false);
    }
  }

  useEffect(() => {
    fetchProva();
    setCarregandoVideo(false); // Simula o carregamento automático do vídeo
    
    return () => {
      setAllQuestions([]);
    };
  }, []);


  async function getNota() {
    try {
      let numCorretas = 0; // Variável para contar respostas corretas.

      // Verifica se `selectedAnswers` está definido e é um objeto.
      if (!selectedAnswers || typeof selectedAnswers !== "object") {
        return 0;
      }

      // Itera sobre os valores de `selectedAnswers` e compara as respostas.
      Object.values(selectedAnswers).forEach((answer) => {
        if (answer.valorPostado === answer.valorCorreto) {
          numCorretas++;
        }
      });

      console.log("Número de respostas corretas:", numCorretas);
      return numCorretas; // Retorna o número de respostas corretas.
    } catch (error) {
      console.error("Erro ao calcular a nota:", error);
      return 0; // Retorna 0 como fallback em caso de erro.
    }
  }

  const [realizouProva, setRealizouProva] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setRealizouProva(false);
        const token = sessionStorage.getItem("authToken");
        if (!token) {
          console.error("Token de autenticação não encontrado.");
          return;
        }

        const userData = JSON.parse(sessionStorage.getItem("user") || "{}");
        if (!userData.id) {
          console.error("Dados do usuário inválidos:", userData);
          return;
        }

        const response = await axios.get(`https://api-protmar-educacional.vercel.app/getverificadorprova/${userData.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        response.data.map((e: any) => {
          if (e.idProva === dataProva[0]._id) {
            setRealizouProva(true)

          }
        })
        

      } catch (error) {
        console.error("Erro ao buscar o verificador da prova:", error);
      }
    };

    
    fetchData(); // Chama a função assíncrona.
  }, [dataProva]); // O array de dependências vazio garante que o efeito seja executado apenas uma vez.

  const [notaGeralDaProva, setNotaGeralDaProva] = useState(0);

  async function handleSubmit() {
  setIsSubmitted(true);

  try {
    const token = sessionStorage.getItem("authToken");
    if (!token) {
      console.error("Token de autenticação não encontrado.");
      return;
    }

    const userData = JSON.parse(sessionStorage.getItem("user") || "{}");
    if (!userData.id) {
      console.error("Erro: Usuário inválido.");
      return;
    }

    if (!shuffledAlternatives || Object.keys(selectedAnswers).length !== shuffledAlternatives.length) {
      console.error("Você não respondeu todas as questões.");
      return;
    }

    const notaObtida = await getNota();
    if (notaObtida === undefined || notaObtida === null) {
      console.error("Erro ao calcular a nota.");
      return;
    }

    if (!dataProva || dataProva.length === 0) {
      console.error("Erro: Nenhuma prova encontrada.");
      return;
    }

    await axios.post(
      "https://api-protmar-educacional.vercel.app/resultadoprova",
      {
        idUsuario: userData.id,
        nomeDaProvaRealizada: dataProva[0].nomeProva,
        notaObtida,
        idProva: dataProva[0]._id,
        curso: categoriaCurso,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );

    console.log("Respostas enviadas com sucesso!");
    
    if (typeof window !== "undefined") {
      window.location.reload();
    }
  } catch (error) {
    console.error("Erro ao enviar as respostas:", error);
  }
}

  




  return (
    <main id="videoPageMain" className="bg-light py-5">
      <Menu />
      <div id="videoPageContainerInfo">
        <h1>{videoData.name}</h1>
        <br />
        {carregandoVideo ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Carregando vídeo...</span>
            </div>
          </div>
        ) : (
          <iframe
            style={{ width: "65%", height: "400px" }}
            src={`https://www.youtube.com/embed/${videoData.url.split("/").pop()}`}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        )}

        <br />

        {realizouProva ? (
          <button type="button" disabled className="btn btn-primary">
            Prova já realizada
          </button>
        ) : (
          <button type="button" className="btn btn-primary" onClick={openModal}>
            Realizar Avaliação
          </button>
        )}

    

        {showModal && (
          <div className="modal fade show" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
            <div className="modal-dialog modal-xl" >
              <div className="modal-content ">
                <div className="modal-header">
                  <h5 className="modal-title">Selecione as Questões</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModalWithConfirmation}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  {carregandoQuestoes ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Carregando questões...</span>
                      </div>
                    </div>
                  ) : shuffledAlternatives.length > 0 ? (
                    shuffledAlternatives.map((questao, index) => (
                      <div key={questao._id} className="question-item mb-4">
                        <h6 className="fw-bold">
                          {index + 1}. {questao.enunciado}
                        </h6>
                        <div className="alternatives mt-2">
                          {questao.alternativas.map((alt, altIndex) => (
                            <div key={altIndex} className="form-check">
                              <input
                                className="form-check-input"
                                type="radio"
                                name={`question-${questao._id}`}
                                value={alt.texto}
                                onChange={(e) =>
                                  setSelectedAnswers((prev) => ({
                                    ...prev,
                                    [questao._id]: {
                                      valorPostado: e.target.value,
                                      valorCorreto: questao.alternativas.find((a) => a.correta)?.texto || "",
                                    },
                                  }))
                                }
                              />
                              <label className="form-check-label">{alt.texto}</label>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-center text-muted">Nenhuma questão disponível no momento.</p>
                  )}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={closeModalWithConfirmation}>
                    Fechar
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      const confirmSubmit = window.confirm(
                        "Você tem certeza que deseja enviar as respostas? Após o envio, não será possível editar."
                      );

                      if (confirmSubmit) {
                        handleSubmit(); // Chama a função para enviar as respostas
                      } else {
                        console.log("Envio cancelado."); // Opcional: apenas para registro
                      }
                    }}
                    disabled={Object.keys(selectedAnswers).length === 0}
                  >
                    Enviar Respostas
                  </button>
                  
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
}
