import 'bootstrap/dist/css/bootstrap.min.css'; // Estilos do Bootstrap
import 'bootstrap-icons/font/bootstrap-icons.css'; // Ícones do Bootstrap, se necessário
import { Menu } from '../menuCompont/Menu';
import './integracao.css';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import axios from 'axios';

interface Video {
  _id: string;
  classVideo: string;
  name: string;
  url: string;
  categoria: string;
}

export function IntegraçãoComponent() {
  const [videos, setVideos] = useState<Video[]>([]);
  const [categorias, setCategorias] = useState<string[]>([]);
  const [dataQuestions, setDataQuestions] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const [selectedAnswers, setSelectedAnswers] = useState<any>({});

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const token = sessionStorage.getItem('authToken'); // Obtenha o token do sessionStorage
        if (!token) {
          console.error("Token não encontrado.");
          return;
        }

        const response = await axios.get("https://api-protmar-educacional.vercel.app/getvideosurl/integracao", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.dataVideos) {
          setVideos(response.data.dataVideos);
        } else {
          console.error("Nenhum vídeo encontrado.");
        }
      } catch (error) {
        console.error("Erro ao buscar vídeos:", error);
      }
    };

    const fetchGetCategorias = async () => {
      try {
        const token = sessionStorage.getItem('authToken');
        const response = await axios.get("https://api-protmar-educacional.vercel.app/getvideosurl/integracao", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Extraindo categorias únicas
        const categoriasUnicas: any = Array.from(
          new Set(response.data.dataVideos.map((e: any) => e.categoria))
        );

        setCategorias(categoriasUnicas);
      } catch (error) {
        console.error('Erro ao buscar categorias:', error);
      }
    };




    const fetchAllQuestions = async () => {
      try {
        const token = sessionStorage.getItem('authToken');
        if (!token) {
          console.error('Token não encontrado.');
          return;
        }

        const question = await axios.get(`https://api-protmar-educacional.vercel.app/getallquestoesbycategoria/integracao`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        // Embaralha as questões
        const suffledQuestions = question.data.sort(() => Math.random() - 0.5);

        // Pega apenas as 10 primeiras questões após embaralhar
        const selectedQuestions = suffledQuestions.slice(0, 10);

        // Randomiza as alternativas de cada questão
        const randomizedQuestions = selectedQuestions.map((questao: any) => {
          const alternativas = [
            { texto: questao.alternativa01, correta: false },
            { texto: questao.alternativa02, correta: false },
            { texto: questao.alternativa03, correta: false },
            { texto: questao.alternativaCorreta, correta: true },
          ];

          // Embaralha as alternativas
          const shuffledAlternatives = alternativas.sort(() => Math.random() - 0.5);

          return {
            ...questao,
            alternativas: shuffledAlternatives,
          };
        });

        // Define as questões com alternativas embaralhadas
        setDataQuestions(randomizedQuestions);

      } catch (error) {
        console.error('Erro ao buscar questões:', error);
        alert('Erro ao carregar questões.');
      }
    };

    fetchGetCategorias()
    fetchVideos();
    fetchAllQuestions();
  }, []);

  const handleAnswerChange = (questionId: string, answer: string) => {
    setSelectedAnswers((prevAnswers: any) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  };

  async function handleSubmit() {
    try {
      const token = sessionStorage.getItem("authToken");
      if (!token) {
        console.error("Token de autenticação não encontrado.");
        return;
      }

      const userData = JSON.parse(sessionStorage.getItem("user") || "");

      // Calcular a nota obtida
      let notaObtida = 0;
      dataQuestions.forEach((questao) => {
        const respostaSelecionada = selectedAnswers[questao._id];
        const alternativaCorreta = questao.alternativas.find((alt: any) => alt.correta === true);

        if (respostaSelecionada === alternativaCorreta.texto) {
          notaObtida += 1; // Incrementa 1 ponto por resposta correta
        }
      });

      // Enviar a nota para o servidor
      await axios.post(
        "https://api-protmar-educacional.vercel.app/resultadoprova",
        {
          idUsuario: userData.id,
          nomeDaProvaRealizada: "PROVA FINAL",
          notaObtida: notaObtida, // Passa a nota calculada
          idProva: "001",
          curso: "integracao"
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Respostas enviadas com sucesso!");
      window.location.reload(); // Atualiza a página após o envio bem-sucedido
    } catch (error) {
      console.error("Erro ao enviar as respostas:", error);
    }
  }

  const [realizouTodasProvas, setRealizouTodasProvas] = useState(false)
  const [realizouProvaFinal, setRealizouProvaFinal] = useState(false);
  const [faltaProvas, setFaltaProvas] = useState(false);
  const verificadorProva = async () => {
    try {
      const token = sessionStorage.getItem("authToken");
      if (!token) {
        console.error("Token de autenticação não encontrado.");
        return;
      }

      const userData = JSON.parse(sessionStorage.getItem("user") || "");

      // Faz a requisição GET para verificar as provas realizadas
      const response = await axios.get(
        `https://api-protmar-educacional.vercel.app/getverificadorprovabycurso/${userData.id}/integracao`,  //RETORNA TODAS AS PROVAS REALIZADAS
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      

      const responseVideos = await axios.get("https://api-protmar-educacional.vercel.app/getvideosurl/integracao", {
        headers: {
          Authorization: `Bearer ${token}`, // Passando o token no cabeçalho
        },
      });


      try {
        const response2 = await axios.get(
          `https://api-protmar-educacional.vercel.app/getnotaprovafinal/${userData.id}/PROVA FINAL`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response2.status === 404) {
          setRealizouProvaFinal(false);
        } else {
          setRealizouProvaFinal(true);
        }
      } catch (error) {
        
      }
      
      if (responseVideos.data.dataVideos.length === response.data.length) {
        setRealizouTodasProvas(true);
        return;
      }


      // // Verifica se a PROVA FINAL CALDEIRASfoi realizada
      // await response.data.map((item: any) => {
      //   if (item.nomeDaProvaRealizada === "PROVA FINAL CALDEIRAS") {
      //     setRealizouProvaFinal(true);
      //     return;
      //   } else {
      //     setRealizouProvaFinal(false);
      //   }

      // });


    } catch (error) {
      console.error("Erro ao verificar a prova:", error);
    }
  };

  const [mediaFinal, setMediaFinal] = useState(0);

  const findNotaFinal = async () => {
    try {
      const token = sessionStorage.getItem("authToken");
      if (!token) {
        console.error("Token de autenticação não encontrado.");
        return;
      }

      const userData = JSON.parse(sessionStorage.getItem("user") || "");

      // Faz a requisição GET para verificar as provas realizadas
      const response = await axios.get(
        `https://api-protmar-educacional.vercel.app/provasrealizadasbyuser/${userData.id}/integracao`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      let nota = 0;
      response.data.forEach((item: any) => {
        nota += item.notaObtida;
      })

      setMediaFinal(Math.ceil(nota / response.data.length))
    } catch (error) {

    }
  }

  const [notaFinalGeral, setNotaFinalGeral] = useState(0)
  const getNotaFinalGeral = async () => {
    try {
      const token = sessionStorage.getItem("authToken");
      if (!token) {
        console.error("Token de autenticação não encontrado.");
        return;
      }

      const userData = JSON.parse(sessionStorage.getItem("user") || "");

      // Faz a requisição GET para verificar as provas realizadas
      const response = await axios.get(
        `https://api-protmar-educacional.vercel.app/getnotaprovafinal/${userData.id}/PROVA FINAL`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setNotaFinalGeral(response.data[0].notaObtida);
    } catch (error) {
      console.log("Prova nao realizada ainda")
    }
  }

  useEffect(() => {
    verificadorProva();
    findNotaFinal();
    getNotaFinalGeral();
  }, [])

  const refazerCurso = async () => {
    try {
      const token = sessionStorage.getItem("authToken");
      if (!token) {
        console.error("Token de autenticação não encontrado.");
        return;
      }

      const userData = JSON.parse(sessionStorage.getItem("user") || "{}");

      if (!userData.id) {
        console.error("Dados do usuário não encontrados.");
        return;
      }

      // Realizando a requisição DELETE para apagar as provas do usuário
      const response = await axios.delete(`https://api-protmar-educacional.vercel.app/deleuserprovas/${userData.id}/integracao`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Verificar a resposta e realizar a recarga da página
      if (response.status === 200) {
        console.log("Provas excluídas com sucesso.");
        window.location.reload();
      } else {
        console.error("Falha ao excluir provas.");
      }
    } catch (error) {
      console.error("Erro ao tentar refazer o curso:", error);
    }
  };

  return (
    <main id="IntegraMain" className="bg-light py-5">
      <Menu />
      <div id="IntegraContainerInfo" className="container">
        <div className="text-center mb-5">
          <h1 id="integraTitle" className="fw-bold">Integração de Segurança</h1>
        </div>

        {
          categorias.map((categoria, index) => (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%'}}>
              <h3>{index + 1}. {categoria}</h3>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: "15px" }}>
                {
                  videos.map((video) => {
                    if (video.categoria === categoria) {
                      return (
                        <div className="card shadow-lg h-100" style={{ width: '300px' }}>
                          <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '300px' }}>
                            <iframe
                              src={`https://www.youtube.com/embed/${video.url.split('/').pop()}`}  // URL convertida para embed
                              title={`YouTube video player ${index + 1}`}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              style={{ width: '90%', height: '90%' }} // Ajustando o iframe para ocupar 80% da largura e altura do contêiner
                            ></iframe>
                          </div>
                          <div className="card-body text-center">
                            <Link to={`/caldeiras/videos/${video._id}`} className="text-dark" state={video}>{video.name}</Link>
                          </div>
                        </div>
                      )
                    }
                  })
                }
              </div>
              <br />
            </div>
          ))
        }











        {/* <div id='integraContainerVideos' className="row row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
          {videos.map((video, index) => (
            <div className="col" key={index}>
              <div className="card shadow-lg h-100">
                <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', height: '300px' }}>
                  <iframe
                    src={`https://www.youtube.com/embed/${video.url.split('/').pop()}`}  // URL convertida para embed
                    title={`YouTube video player ${index + 1}`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{ width: '90%', height: '90%' }} // Ajustando o iframe para ocupar 80% da largura e altura do contêiner
                  ></iframe>
                </div>
                <div className="card-body text-center">
                  <Link to={`/caldeiras/videos/${video._id}`} className="text-dark" state={video}>{video.name}</Link>
                </div>
              </div>
            </div>
          ))}
        </div> */}

        <br />

        {
          notaFinalGeral >= 7 ? (
            <div>
              <button
                type="button"
                disabled
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                PROVA FINAL CALDEIRAS Já Realizada
              </button>
              <br />
              <Link to="/certificadointegracao">Emitir Certificado</Link>
            </div>
          ) : realizouProvaFinal ? (
            <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={refazerCurso}
            >
              Você não passou na prova final! <br />
              Clique para refazer o curso.
            </button>
          ) : mediaFinal >= 7 && realizouTodasProvas ? (
            <button
              type="button"
              onClick={openModal}
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Realizar Prova Final
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-primary"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={refazerCurso}
            >
              Média insuficiente! <br />
              Ou faltam provas a realizar. <br />
              Clique para refazer o curso.
            </button>
          )
        }

        <br />

        {/* {
          // Se a nota final geral for maior ou igual a 7, mostra o link
          notaFinalGeral !== undefined && notaFinalGeral >= 7 ? (
            <Link to="/certificado">Emitir Certificado</Link>
          ) : (
            // Caso a nota final geral seja menor que 7, mostra o botão para refazer o curso
            notaFinalGeral !== undefined && (
              <button
                type="button"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={refazerCurso}
              >
                Media insuficiente! <br />
                Clique para refazer o curso.
              </button>
            )
          )
        } */}



        {showModal && (
          <div className="modal fade show" style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
            <div className="modal-dialog modal-xl" >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Selecione as Questões</h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={closeModal}
                    aria-label="Close"
                  ></button>
                </div>
                <div className="modal-body">
                  {dataQuestions.map((questao) => (
                    <div key={questao._id} className="question-item mb-4">
                      <h6 className="fw-bold">{questao.enunciado}</h6>
                      <div className="alternatives mt-2">
                        {questao.alternativas.map((alt: any, altIndex: any) => (
                          <div key={altIndex} className="form-check">
                            <input
                              className="form-check-input"
                              type="radio"
                              name={`question-${questao._id}`}
                              id={`question-${questao._id}-${altIndex}`}
                              value={alt.texto}
                              onChange={() => handleAnswerChange(questao._id, alt.texto)}
                            />
                            <label className="form-check-label" htmlFor={`question-${questao._id}-${altIndex}`}>
                              {alt.texto}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={closeModal}>
                    Fechar
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      const confirmSubmit = window.confirm(
                        "Você tem certeza que deseja enviar as respostas? Após o envio, não será possível editar."
                      );

                      if (confirmSubmit) {
                        handleSubmit(); // Chama a função para enviar as respostas
                      } else {
                        console.log("Envio cancelado."); // Opcional: apenas para registro
                      }
                    }}
                    className="btn btn-primary"
                  >
                    Enviar Respostas
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  );
}
