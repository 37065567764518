import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute2 = ({ children }: { children: React.ReactNode }) => {
  const [notaFinal1, setNotaFinal1] = useState<number | null>(null);
  const [notaFinal2, setNotaFinal2] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNotaFinal = async () => {
      try {
        const token = sessionStorage.getItem("authToken");
        if (!token) {
          console.error("Token não encontrado");
          return;
        }

        const userData = JSON.parse(sessionStorage.getItem("user") || "{}");

        // const { data: response1 } = await axios.get(
        //   `https://api-protmar-educacional.vercel.app/getnotaprovafinal/${userData.id}/PROVA FINAL`,
        //   {
        //     headers: { Authorization: `Bearer ${token}` },
        //   }
        // );

        const { data: response2 } = await axios.get(
          `https://api-protmar-educacional.vercel.app/getnotaprovafinal/${userData.id}/PROVA FINAL CALDEIRAS`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        // setNotaFinal1(response1[0]?.notaObtida ?? 0);
        setNotaFinal2(response2[0]?.notaObtida ?? 0);
      } catch (error) {
        console.error("Erro ao buscar a nota final", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotaFinal();
  }, []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  // Apenas renderiza os filhos se a nota final 2 for válida e maior ou igual a 7
  return notaFinal2 !== null && notaFinal2 >= 7 ? <>{children}</> : <Navigate to="/error" />;
};

export default ProtectedRoute2;
