import React, { useEffect, useState } from "react";
import { Menu } from "../menuCompont/Menu";
import "./adminPage.css";
import axios from "axios";
import { Link } from "react-router-dom";

interface Video {
  _id: string;
  classVideo: string;
  name: string;
  url: string;
}

export function AdminPage() {
  const [videos, setVideos] = useState<Video[]>([]);
  const [videosCaldeiras, setVideosCaldeiras] = useState<Video[]>([]);
  const [newVideo, setNewVideo] = useState({
    url: "",
    name: "",
    classVideo: "",
    categoria: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const token = sessionStorage.getItem("authToken"); // Obtenha o token do sessionStorage
        if (!token) {
          console.error("Token não encontrado.");
          return;
        }

        const response = await axios.get("https://api-protmar-educacional.vercel.app/getvideosurl/integracao", {
          headers: {
            Authorization: `Bearer ${token}`, // Passando o token no cabeçalho
          },
        });

        if (response.data.dataVideos) {
          setVideos(response.data.dataVideos);
        } else {
          console.error("Nenhum vídeo encontrado.");
        }
      } catch (error) {
        console.error("Erro ao buscar vídeos:", error);
      }
    };

    const fetchVideosCaldeiras = async () => {
      try {
        const token = sessionStorage.getItem("authToken"); // Obtenha o token do sessionStorage
        if (!token) {
          console.error("Token não encontrado.");
          return;
        }

        const response = await axios.get("https://api-protmar-educacional.vercel.app/getvideosurl/caldeiras", {
          headers: {
            Authorization: `Bearer ${token}`, // Passando o token no cabeçalho
          },
        });

        if (response.data.dataVideos) {
          setVideosCaldeiras(response.data.dataVideos);
        } else {
          console.error("Nenhum vídeo encontrado.");
        }
      } catch (error) {
        console.error("Erro ao buscar vídeos:", error);
      }
    };

    fetchVideosCaldeiras()
    fetchVideos();
  }, []);

  
  console.log(newVideo.classVideo)

  const handleChange = (e: any) => {
    setNewVideo({ ...newVideo, [e.target.name]: e.target.value });
  };

  const handleAddVideo = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!newVideo.url || !newVideo.name || !newVideo.classVideo || !newVideo.categoria) {
      alert('Todos os campos são obrigatórios');
      return;
    }

    try {
      const token = sessionStorage.getItem("authToken");
      if (!token) {
        console.error("Token não encontrado.");
        return;
      } 

      const response = await axios.post(
        "https://api-protmar-educacional.vercel.app/postvideosurl",
        newVideo,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );  

      const userData = JSON.parse(sessionStorage.getItem("user") || "");

      if (newVideo.classVideo === "integracao") {
        await axios.delete(`https://api-protmar-educacional.vercel.app/deleuserprovas/${userData?.id}/integracao`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      }

      if (newVideo.classVideo === "caldeiras") {
        await axios.delete(`https://api-protmar-educacional.vercel.app/deleuserprovas/${userData?.id}/caldeiras`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      }

      if (response.status === 201) {
        setVideos([...videos, response.data.video]);
        setNewVideo({ url: "", name: "", classVideo: "", categoria: "" });
        alert("Vídeo adicionado com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao adicionar vídeo:", error);
    }
  };

  async function handleDelete(id: string) {
    try {
      const token = sessionStorage.getItem("authToken");
      if (!token) {
        console.error("Token não encontrado.");
        return;
      }

      const response = await axios.delete(`https://api-protmar-educacional.vercel.app/deletevideourl/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        window.location.reload();
        alert("Vídeo excluído com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao excluir vídeo:", error);
    }
  }

  async function handleDeleteCaldeiras(id: string) {
    try {
      const token = sessionStorage.getItem("authToken");
      if (!token) {
        console.error("Token não encontrado.");
        return;
      }

      const response = await axios.delete(`https://api-protmar-educacional.vercel.app/deletevideourl/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        window.location.reload();
        alert("Vídeo excluído com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao excluir vídeo:", error);
    }
  }

  const renderTable = (videos:any, handleDelete:any) => (
    <table className="table">
      <thead>
        <tr>
          <th style={{ width: "20%" }}>ID</th>
          <th style={{ width: "20%" }}>NOME</th>
          <th style={{ width: "20%" }}>URL</th>
          <th style={{ width: "20%" }}>CLASSE VIDEO</th>
          <th style={{ width: "20%" }}>AÇÃO</th>
        </tr>
      </thead>
      <tbody>
        {videos.map((video:any) => (
          <tr key={video._id}>
            <td>{video._id}</td>
            <td>{video.name}</td>
            <td>
              <a href={video.url} target="_blank" rel="noopener noreferrer">
                {video.url}
              </a>
            </td>
            <td>{video.classVideo}</td>
            <td>
              <button
                style={{ marginRight: "10px" }}
                onClick={() => {
                  if (window.confirm("Tem certeza que deseja excluir este item?")) {
                    handleDelete(video._id);
                  }
                }}
                className="btn btn-danger"
              >
                Excluir
              </button>
  
              <Link to={`/admin/questoes/${video._id}`} state={video} className="btn btn-primary">
                Questões
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
  

  return (
    <main id="adminMain">
      <Menu />
      <div>
        <div className="text-center mb-5">
          <h1 id="adminTitle" className="fw-bold">
            Tela de Administrador
          </h1>
        </div>

        {/* Formulário de Adicionar Vídeo */}
        <div className="mb-4" style={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
          <h2>Adicionar Novo Vídeo</h2>
          {error && <div className="alert alert-danger">{error}</div>}
          <form onSubmit={handleAddVideo} style={{width:"92vw"}}>
            <div className="mb-3">
              <label htmlFor="url" className="form-label">
                URL do Vídeo
              </label>
              <input
                type="url"
                className="form-control"
                id="url"
                name="url"
                value={newVideo.url}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Nome do Vídeo
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={newVideo.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="classVideo" className="form-label">
                Classe do Vídeo
              </label>
              <select
                className="form-select"
                id="classVideo"
                name="classVideo"
                value={newVideo.classVideo}
                onChange={handleChange}
              >
                <option value="">Selecione a classe</option>
                <option value="integracao">Integração</option>
                <option value="caldeiras">Caldeiras</option>
              </select>
            </div>
            <div className="mb-3">
              <label htmlFor="categoria" className="form-label">Categoria do Vídeo</label>
              <input
                type="text"
                className="form-control"
                id="categoria"
                name="categoria"
                value={newVideo.categoria}
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Adicionar Vídeo
            </button>
          </form>
        </div>

        {/* Tabela com os vídeos */}
        <h6>Integração</h6>
        {renderTable(videos, handleDelete)}

        <h6>Caldeiras</h6>
        {renderTable(videosCaldeiras, handleDeleteCaldeiras)}
      </div>
    </main>
  );
}
