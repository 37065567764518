import React, { useState } from "react";
import "./menuCss.css";
import { Link } from "react-router-dom";

export function Menu() {
  const [isOpen, setIsOpen] = useState(false);

  const viewIntegracaoIcon = (
    <section id="MenuContainerIcons">
      <div className="MenuContainerText">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-journal-bookmark-fill"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M6 1h6v7a.5.5 0 0 1-.757.429L9 7.083 6.757 8.43A.5.5 0 0 1 6 8z"
          />
          <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2" />
          <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1z" />
        </svg>

        {isOpen ? (
          <Link to="/integracao" className="MenuTextIcon">
            Integração de Segurança do Trabalho
          </Link>
        ) : (
          <h1></h1>
        )}
      </div>
    </section>
  );

  const viewCaldeiraIcon = (
    <section id="MenuContainerIcons">
      <div className="MenuContainerText">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bucket" viewBox="0 0 16 16">
          <path d="M2.522 5H2a.5.5 0 0 0-.494.574l1.372 9.149A1.5 1.5 0 0 0 4.36 16h7.278a1.5 1.5 0 0 0 1.483-1.277l1.373-9.149A.5.5 0 0 0 14 5h-.522A5.5 5.5 0 0 0 2.522 5m1.005 0a4.5 4.5 0 0 1 8.945 0zm9.892 1-1.286 8.574a.5.5 0 0 1-.494.426H4.36a.5.5 0 0 1-.494-.426L2.58 6h10.838z" />
        </svg>

        {isOpen ? (
          <Link to="/caldeiras" className="MenuTextIcon">
            NR-13 Caldeiras
          </Link>
        ) : (
          <h1></h1>
        )}
      </div>
    </section>
  );

  function getAcessNivel() {
    const token = sessionStorage.getItem('authToken');

    if (token) {
      const tokenPayload = JSON.parse(atob(token.split('.')[1])); // Decodificando a parte do payload
      return tokenPayload.acesso;
    }

  }

  const viewAdminIcon = (
    <section id="MenuContainerIcons">
      <div className="MenuContainerText">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-bug" viewBox="0 0 16 16">
          <path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A5 5 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A5 5 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623M4 7v4a4 4 0 0 0 3.5 3.97V7zm4.5 0v7.97A4 4 0 0 0 12 11V7zM12 6a4 4 0 0 0-1.334-2.982A3.98 3.98 0 0 0 8 2a3.98 3.98 0 0 0-2.667 1.018A4 4 0 0 0 4 6z" />
        </svg>

        {isOpen ? (
          <Link to="/admin" className="MenuTextIcon">
            Admin
          </Link>
        ) : (
          <h1></h1>
        )}
      </div>
    </section>
  );

  return (
    <main id="MenuMain" style={isOpen ? { width: "250px" } : { width: "50px" }}>
      <div
        id="MenuContainerIconCloseMenu"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <svg
          id="MenuIcon01"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-list"
          viewBox="0 0 16 16"
        >
          <path
            fillRule="evenodd"
            d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
          />
        </svg>
      </div>

      <div>{viewIntegracaoIcon}</div>
      <div>{viewCaldeiraIcon}</div>

      <div>{getAcessNivel() === "admin" ? viewAdminIcon : <h1></h1>}</div>
    </main>
  );
}
