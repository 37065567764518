import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';

const ProtectedRoute3 = ({ children }: { children: React.ReactNode }) => {
  const [notaFinal2, setNotaFinal2] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchNotaFinal = async () => {
      try {
        const token = sessionStorage.getItem("authToken");
        if (!token) {
          console.error("Token não encontrado");
          return;
        }

        const userDataString = sessionStorage.getItem("user");
        if (!userDataString) {
          console.error("Dados do usuário não encontrados");
          return;
        }

        const userData = JSON.parse(userDataString);
        
        if (!userData.id) {
          console.error("ID do usuário não encontrado");
          return;
        }

        const { data: response1 } = await axios.get(
          `https://api-protmar-educacional.vercel.app/getnotaprovafinal/${userData.id}/PROVA FINAL`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        setNotaFinal2(response1[0]?.notaObtida ?? 0);
      } catch (error) {
        console.error("Erro ao buscar a nota final", error);
      } finally {
        setLoading(false);
      }
    };

    fetchNotaFinal();
  }, []);

  if (loading) {
    return <div>Carregando...</div>;
  }

  return notaFinal2 !== null && notaFinal2 >= 7 ? <>{children}</> : <Navigate to="/error" />;
};

export default ProtectedRoute3;
