import React from "react";

export default function LinksApostilas() {
    return (
        <main style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
            <h1>Links para apostilas</h1>
            <ul style={{ display:"flex", gap:"10px", listStyle:"none" }}>
                <li><a href="https://drive.google.com/file/d/1160CyLtT4qS9-ywtRXHlVTXY3xn0nS8Q/view?usp=drive_link">Apostila 1</a></li>
                <li><a href="https://drive.google.com/file/d/1JvJ8Vv6tA8Md6znwTdWRLhe6X6p-wfNO/view?usp=drive_link">Apostila 2</a></li>
                <li><a href="https://drive.google.com/file/d/1LtRE0OCvlxdHFpg16Ek4yjHVKzvvpQCE/view?usp=drive_link">Apostila 3</a></li>
                <li><a href="https://drive.google.com/file/d/1_HqcDshf1703jUATRAAXphr4ZSb6eu7C/view?usp=drive_link">Apostila 4</a></li>
                <li><a href="https://drive.google.com/file/d/1HoALgGbtpsBaPOU24uO10lcXuKOlaKkt/view?usp=drive_link">Apostila 5</a></li>
                <li><a href="https://drive.google.com/file/d/1UHxQITAm0ARUwaqnhXBFqtHL8rPuNyp0/view?usp=drive_link">Apostila 6</a></li>
                <li><a href="https://drive.google.com/file/d/1NTspjNj8UNJ_1ZvOOMPYkCnsuEY6adYM/view?usp=drive_link">Apostila 7</a></li>
                <li><a href="https://drive.google.com/file/d/12cQzCR4lPPXZhydg9Urex9Ams_brX61m/view?usp=drive_link">Apostila 8</a></li>
            </ul>
        </main>
    )
}