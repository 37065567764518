import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  const [isLogin, setIsLogin] = useState(true); // Alterna entre login e registro
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [cpf, setCpf] = useState('');
  const [rg, setRg] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [nomeMae, setNomeMae] = useState('');
  const [name, setName] = useState('');
  const navigate = useNavigate();

  async function makeLogin() {
    if (login === '' || password === '') {
      alert('Usuário e senha são obrigatórios!');
      return;
    }
    try {
      const response = await axios.post('https://api-protmar-educacional.vercel.app/loginuser', {
        login,
        senha: password,
      });

      if (response.status === 200) {
        const { token } = response.data.user;
        sessionStorage.setItem('authToken', token);
        sessionStorage.setItem('user', JSON.stringify(response.data.user));
        navigate('/integracao');
      }
    } catch (error) {
      console.error('Erro ao fazer login:', error);
      alert('Login falhou! Verifique suas credenciais.');
    }
  }

  async function makeRegister() {
    if (!login || !password || !cpf || !rg || !nascimento || !nomeMae || !name) {
      alert('Todos os campos são obrigatórios!');
      return;
    }
    try {
      const response = await axios.post('https://api-protmar-educacional.vercel.app/adduser', {
        name,
        login,
        senha: password,
        cpf,
        rg,
        nascimento,
        nomeMae,
        nivel_acesso: "user"
      });

      if (response.status === 201) {
        alert('Cadastro realizado com sucesso! Faça login.');
        setIsLogin(true);
      }
    } catch (error) {
      console.error('Erro ao cadastrar:', error);
      alert('Erro ao cadastrar. Tente novamente.');
    }
  }

  return (
    <main className="d-flex justify-content-center align-items-center vh-100">
      <div className="border p-4 rounded shadow-sm">
        <h2 className="text-center mb-4">Protmar Educacional</h2>

        {isLogin ? (
          <>
            <input
              onChange={(e) => setLogin(e.target.value)}
              type="text"
              className="form-control mb-3"
              placeholder="Usuário"
              value={login}
            />
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="form-control mb-3"
              placeholder="Senha"
              value={password}
            />
            <button onClick={makeLogin} className="btn btn-primary w-100">
              Login
            </button>
            <p className="text-center mt-3">
              Não tem uma conta?{' '}
              <span className="text-primary" style={{ cursor: 'pointer' }} onClick={() => setIsLogin(false)}>
                Cadastre-se
              </span>
            </p>
          </>
        ) : (
          <>
           Nome Completo
            <input
              onChange={(e) => setName(e.target.value)}
              type="text"
              className="form-control mb-3"
              placeholder="Nome Completo"
              value={name}
            />
          Usuário
            <input
              onChange={(e) => setLogin(e.target.value)}
              type="text"
              className="form-control mb-3"
              placeholder="Usuário"
              value={login}
            />
            Senha
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="form-control mb-3"
              placeholder="Senha"
              value={password}
            />
            CPF
            <input
              onChange={(e) => setCpf(e.target.value)}
              type="text"
              className="form-control mb-3"
              placeholder="CPF"
              value={cpf}
            />
            RG
            <input
              onChange={(e) => setRg(e.target.value)}
              type="text"
              className="form-control mb-3"
              placeholder="RG"
              value={rg}
            />
            Data de Nascimento
            <input
              onChange={(e) => setNascimento(e.target.value)}
              type="date"
              className="form-control mb-3"
              placeholder="Data de Nascimento"
              value={nascimento}
            />

            Nome da Mãe
            <input
              onChange={(e) => setNomeMae(e.target.value)}
              type="text"
              className="form-control mb-3"
              placeholder="Nome da Mãe"
              value={nomeMae}
            />
            <button onClick={makeRegister} className="btn btn-success w-100">
              Cadastrar
            </button>
            <p className="text-center mt-3">
              Já tem uma conta?{' '}
              <span className="text-primary" style={{ cursor: 'pointer' }} onClick={() => setIsLogin(true)}>
                Faça login
              </span>
            </p>
          </>
        )}
      </div>
    </main>
  );
}

export default App;
